<template>
  <div class="animated fadeIn">
    <div>
      <b-row>
        <div class="col-12">
          <b-card>
            <b-row class="mx-md-n3 mx-1">
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-md-0 mb-3">
                <div v-if="loadingWarehouses">
                  <loading-animation />
                </div>
                <model-list-select
                  v-else
                  :list="warehouses"
                  option-value="id"
                  option-text="name"
                  v-model="selectedWarehouse"
                  placeholder="Selecione o Armazém"
                />
              </div>
              <div class="non-small-hide mobile-hide col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                <b-dropdown id="new-adjustment-dropdown" text="Nova Realocação" variant="success" class="w-100">
                  <b-link to="/orders/item-reallocation/new" class="dropdown-item">Padrão</b-link>
                  <b-link to="/orders/item-reallocation/new?singleLocation=1" class="dropdown-item">Endereço único</b-link>
                </b-dropdown>
              </div>
              <div class="col-xl-6 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="row">
                  <div class="col">
                    <b-input-group>
                      <b-form-input
                        v-model="filter"
                        @keyup.enter="onFiltering"
                        type="number"
                        placeholder="Procurar por..."
                      />
                      <b-input-group-append>
                        <b-button size="md" text="Button" variant="outline-info" @click.passive="onFiltering">
                          <i class="cui-magnifying-glass"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="col-auto pl-0">
                    <div class="row justify-content-md-start justify-content-end">
                      <div class="col-auto">
                        <button type="button" class="btn btn-outline-info cursor-pointer" @click="changeSortOption" v-b-tooltip.hover
                          :title="'Ordenação - ' + selectedSortOption.label">
                          <i :class="'fa fa-' + selectedSortOption.icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-hide col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-3 mt-sm-0">
                <b-dropdown id="new-adjustment-dropdown" text="Nova Realocação" variant="primary" class="w-100">
                  <b-link to="/orders/item-reallocation/new" class="dropdown-item">Padrão</b-link>
                  <b-link to="/orders/item-reallocation/new?singleLocation=1" class="dropdown-item">Endereço único</b-link>
                </b-dropdown>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-sm-3 mt-2">
                <div class="row align-items-center">
                  <div class="col-sm-auto col-12 mb-sm-0 mb-1">
                    <label class="mb-0">Item:</label>
                  </div>
                  <div class="col">
                    <div v-if="loadingItems">
                      <loading-animation />
                    </div>
                    <model-list-select
                      v-else
                      :list="items"
                      option-value="id"
                      option-text="label"
                      v-model="selectedItem"
                      placeholder="Digite para pesquisar..."
                      @searchchange="searchItem"
                      @keyup.enter="onFiltering"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>
                </div>
              </div>
            </b-row>
          </b-card>
        </div>
      </b-row>

      <b-row>
        <div class="col-12">
          <b-tabs
            lazy
          >
            <b-tab :active="activeTabIndex == 0" @click="tabChanged" class="px-md-3 px-0">
              <template slot="title">
                <p>Realocações Pendentes</p>
              </template>

              <OrderTable
                id="released"
                urlStart="/orders/item-reallocation/start/"
                resourceName="orderResourceList"
                :url="endpoints.findAll"
                role="PEN"
                movementType="RAL"
                ref="releasedTable"
                :enableEdit="true"
                :enableCancel="true"
                urlEdit="/orders/item-reallocation/edit/"
              >

              </OrderTable>
            </b-tab>
            <b-tab :active="activeTabIndex == 1" @click="tabChanged" class="px-md-3 px-0">
              <template slot="title">
                <p>Realocações Finalizadas</p>
              </template>
              <OrderTable
                id="complete"
                urlStart="/orders/item-reallocation/start/"
                resourceName="orderResourceList"
                :url="endpoints.findAll"
                role="FIN"
                movementType="RAL"
                ref="completeTable"
              />
            </b-tab>
            <b-tab :active="activeTabIndex == 2" @click="tabChanged" class="px-md-3 px-0">
              <template slot="title">
                <p>Realocações Canceladas</p>
              </template>
              <OrderTable
                id="canceled"
                resourceName="orderResourceList"
                :url="endpoints.findAll"
                role="CAN"
                movementType="RAL"
                ref="canceledTable"
              />
            </b-tab>
          </b-tabs>
        </div>
      </b-row>

    </div>
  </div>
</template>

<script>
  import { OrderTable}  from '@/components/tables'
  import { ModelListSelect } from 'vue-search-select'
  import { httpClient } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared'

  export default {
    name: 'items-reallocation-all',

    components: { OrderTable, ModelListSelect, LoadingAnimation },

    data () {
      return {
        endpoints: {
          findAll: process.env.VUE_APP_API_URL + 'orders'
        },
        filter: null,
        warehouses: [],
        selectedWarehouse: {},
        loadingWarehouses: false,
        sortOptions: [
          { value: 'asc', label: 'Ascendente', icon: 'sort-down' },
          { value: 'desc', label: 'Descendente', icon: 'sort-up' }
        ],
        selectedSortOption: {},
        items: [{id: null, label: 'Todos'}],
        loadingItems: false,
        selectedItem: {},
        activeTabIndex: 0,
      }
    },

    beforeMount() {
      this.activeTabIndex = Number(shared.getLocalStorageString('RAL/activeTabIndex') || 0);
      this.filter = shared.getLocalStorageString('RAL/filterText');
      this.selectedSortOption = shared.getLocalStorageObj('order/selectedSortOption');

      if (!this.selectedSortOption || !this.selectedSortOption.value) {
        this.selectedSortOption = this.sortOptions[0];
      }
    },

    mounted : async function () {
      //const { allOrderIn, allOrderInComplete } = localStorage.getItem('endpoints/order');
      //this.urlAllPrepare = allOrderIn.href
      //this.urlAllComplete = allOrderInComplete.href

      await this.$store.dispatch('app/changePage', { title:'Realocações', size: 4 })
      this.getWarehouses();
      this.selectedWarehouse = shared.getLocalStorageObj('order/warehouse');

      this.selectedItem = shared.getLocalStorageObj('order/filterItem');
      if (this.selectedItem && this.selectedItem.id && Object.keys(this.selectedItem).length) {
        this.items.push(this.selectedItem);
        this.setItemsLabel();
      }

      this.onFiltering();
    },

    beforeRouteLeave (to, from, next) {
      this.getTable().beforeRouteLeave();
      next(true);
    },

    methods: {
      getTable() {
        return this.$refs.releasedTable || this.$refs.inCheckTable || this.$refs.completeTable || this.$refs.canceledTable;
      },

      onFiltering() {
        shared.setLocalStorageString('RAL/filterText', this.filter);

        if (this.$refs.releasedTable)
          shared.setLocalStorageString('RAL/activeTabIndex', 0);

        else if (this.$refs.completeTable)
          shared.setLocalStorageString('RAL/activeTabIndex', 1);

        else if (this.$refs.canceledTable)
          shared.setLocalStorageString('RAL/activeTabIndex', 2);

        this.getTable().filter({
          filterText: this.filter,
          warehouse: this.selectedWarehouse,
          item: this.selectedItem,
          operation: {},
          page: null,
          sort: this.selectedSortOption.value,
          ticketStatus: {},
          location: {},
          route: {}
        });
      },

      getWarehouses() {
        this.loadingWarehouses = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-locales/condition?page=${0}&size=${100}`, {
          conditions: [{
            field: 'active',
            value: true
          }]
        }).then(({ data }) => {
          this.loadingWarehouses = false;
          this.warehouses = data.data.content;
          this.warehouses.unshift({id: null, name: 'Todos'});
        })
        .catch((error) => {
          this.loadingWarehouses = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os armazéns");
          }
        });
      },

      tabChanged() {
        this.$nextTick(() => {
          this.onFiltering();
        });
      },

      changeSortOption() {
        if (this.selectedSortOption.value === this.sortOptions[0].value) {
          this.selectedSortOption = this.sortOptions[1];
        } else {
          this.selectedSortOption = this.sortOptions[0];
        }

        shared.setLocalStorageObj('order/selectedSortOption', this.selectedSortOption);
        this.onFiltering();
      },

      async searchItem (searchText) {
        if (!this.searchItemInProcess && (
          (searchText && searchText.length) || !this.selectedItem || Object.keys(this.selectedItem).length == 0
        )) {
          if (this.itemSearchTimeout) {
            clearTimeout(this.itemSearchTimeout);
          }

          this.itemSearchTimeout = setTimeout(() => {
            this.searchItemInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}items/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      },
                      {
                        field: 'sku',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      },
                      {
                        field: 'gtin',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
                this.items.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
                this.items.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleData(data) {
        this.searchItemInProcess = false
        this.totalRows = data.data.totalElements
        const items = data.data.content

        return items
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      setItemsLabel(searchText = null) {
        if (this.items && this.items.length) {
          this.items.forEach(item => {
            if (item.id) {
              item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
            }
            if(searchText){
              if(item.sku && (item.sku.indexOf(searchText) >= 0)){
                item.label += ' - Ref: ' + item.sku;
              }
              if(item.gtin && (item.gtin.indexOf(searchText) >= 0)){
                item.label += ' - Cód barras: ' + item.gtin;
              }
            }
          });
        }
      },

      alwaysTrueFilterPredicate() {
        return true;
      }
    },

    watch: {
      'selectedWarehouse': function(newVal, oldVal) {
        shared.setLocalStorageObj('order/warehouse', this.selectedWarehouse);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
      'selectedItem': function(newVal, oldVal) {
        shared.setLocalStorageObj('order/filterItem', this.selectedItem);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
    }
  }
</script>
