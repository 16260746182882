import { render, staticRenderFns } from "./reallocation-all.vue?vue&type=template&id=7b6f87de&"
import script from "./reallocation-all.vue?vue&type=script&lang=js&"
export * from "./reallocation-all.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports